<template>
  <v-form
    ref="form"
    v-model="okValue"
    lazy-validation
  >
    <v-container fluid>
      <v-row
        align="start"
        style="width: calc(100% + 20px)"
        class="ml-2 my-2"
      >
        <v-col
          v-for="field of foptype.fields"
          :key="field._id"
          cols="12"
          :md="sizeField(field)"
          class="pl-2 pr-2 py-0"
        >
          <FopField
            :value="getValue(field._id)"
            :field="field"
            @input="setValue(field._id, $event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FopField from "@/components/Event/FopField";

export default {
  name: "AddFopSection",
  components: {
    FopField,
  },
  props: {
    value: Array,
    foptype: Object,
  },
  computed: {
    okValue: {
      set(v) {
        this.$emit("update:ok", v);
      },
      get() {
        return this.ok;
      },
    },
  },
  methods: {
    getValue(id) {
      return this.value.filter((x) => x.field_id == id).map((x) => x.value)[0];
    },
    setValue(id, val) {
      let v = this.value.filter((x) => x.field_id != id);
      if (!!val) v.push({ field_id: id, value: val });
      this.$emit("input", v);
    },
    validate() {
      return this.$refs.form.validate();
    },
    sizeField(field) {
      // Size for medium+ displays (otherwise 12)
      if (["date", "textarea"].includes(field.field_type)) {
        return 12;
      }
      return 3;
    },
  },
};
</script>