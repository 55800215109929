<template>
  <v-row>
    <v-col
      :cols="miniScreen ? 12 : undefined"
      class="pl-2 pb-0"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(date)"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            :rules="[dateRule]"
            :hide-details="miniScreen ? 'auto' : false"
            :outlined="outlined"
            :label="label"
            :class="'pt-0' + (!miniScreen ? 'mr-1' : '')"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="computedDateFormatted"
          no-title
          :min="minDate"
          @input="menu = false"
        />
      </v-menu>
    </v-col>
    <v-col :cols="miniScreen ? 12 : undefined">
      <v-row :class="!miniScreen ? 'mr-n5' : 'pl-0 pr-4 mb-1'">
        <v-text-field
          v-model="time"
          append-icon="mdi-clock-outline"
          type="time"
          :label="label"
          :rules="[dateRule]"
          :hide-details="miniScreen ? 'auto' : false"
          :outlined="outlined"
          class="mt-6 pt-0 ml-2"
        />
        <v-icon
          v-if="!required"
          :class="'ml-4 mr-n3 mt-7 mb-6'"
          @click="reset"
        >
          mdi-close-circle-outline
        </v-icon>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VInput from "vuetify/lib/components/VInput/VInput.js";

export default {
  name: "TimeSelector",
  extends: VInput,
  props: {
    value: Date,
    label: String,
    required: Boolean,
    beginDate: Date, // renseigner pour activer la règle sur la date de fin
    endDate: Date, // renseigner pour activer la règle sur la date de début
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    miniScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    computedDateFormatted: {
      get() {
        if (!this.date) return undefined;
        const [day, month, year] = this.date
          .toLocaleDateString("fr-FR", {
            dateStyle: "short",
          })
          .split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      set(val) {
        if (!!val) this.date = new Date(val + "T00:00:00");
      },
    },
    date: {
      get() {
        return this.value && new Date(this.value.toDateString());
      },
      set(val) {
        if (!val && !this.time) this.$emit("input", undefined);
        const date = new Date(val.getTime() + this.timeSecs(this.time));
        this.$emit("input", date);
      },
    },
    time: {
      get() {
        return (
          this.value &&
          this.value.getHours().toString().padStart(2, "0") +
            ":" +
            this.value.getMinutes().toString().padStart(2, "0")
        );
      },
      set(val) {
        if (!this.date && !val) this.$emit("input", undefined);
        const now = new Date();
        const timeDate = !this.date
          ? new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
          : this.date.getTime();
        const date = new Date(timeDate + this.timeSecs(val));
        this.$emit("input", date);
      },
    },
    minDate() {
      return new Date().toISOString();
    },
    dateRule() {
      if (this.required && !this.value) return "Ce champs est requis";
      if (this.beginDate && this.value < this.beginDate)
        return "La date de fin doit être supérieure à celle de début";
      else if (this.endDate && this.value > this.endDate)
        return "La date de début doit être inférieure à celle de fin";
      return true;
    },
  },
  methods: {
    formatDate(date) {
      return date && date.toLocaleDateString("fr-FR", { dateStyle: "short" });
    },
    timeSecs(val) {
      if (!val) return 0;
      const [hours, minutes] = val.split(":");
      return (hours * 3600 + minutes * 60) * 1000;
    },
    reset() {
      this.$emit("input", undefined);
    },
  },
};
</script>

<style>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
</style>