<template>
  <v-form
    ref="form"
    v-model="okValue"
    lazy-validation
  >
    <v-container fluid>
      <!-- Cancel the stepper margin -->
      <v-row
        dense
        style="max-width: calc(100% - 60px)"
        align="end"
      >
        <v-col cols="6">
          <v-text-field
            v-model="value.name"
            :rules="notEmptyStringRule"
            label="Nom de l'événement"
            required
            class="mr-4"
          />
        </v-col>
        <v-col>
          <InputSearch
            v-model="value.event_details.locations"
            type="location"
            multiple
            :text="(x) => x.name"
            label="Lieux"
            hint="Vous pouvez saisir plusieurs lieux"
            chips
            single-line
          />
        </v-col>
        <v-col
          cols="12"
          class="mb-2 ml-1"
        >
          <TimeSelector
            v-model="value.event_details.begin_date"
            :end-date="value.event_details.end_date"
            :required="true"
            label="Date de début de l'évènement"
          />
        </v-col>
        <v-col
          cols="12"
          class="mb-2 ml-1"
        >
          <TimeSelector
            v-model="value.event_details.end_date"
            :begin-date="value.event_details.begin_date"
            :required="true"
            label="Date de fin de l'évènement"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="value.event_details.description"
            :rules="notEmptyStringRule"
            hide-details="auto"
            label="Description brève"
            rows="3"
            auto-grow
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import TimeSelector from "@/components/Inputs/TimeSelector";
import InputSearch from "@/components/Inputs/InputSearch";

export default {
  name: "EventDescriptionEdit",
  components: {
    TimeSelector,
    InputSearch,
  },
  props: {
    value: Object,
    ok: Boolean,
  },
  data: () => {
    return {
      notEmptyStringRule: [(v) => (!!v && !!v.trim()) || "Champ requis"],
    };
  },
  computed: {
    okValue: {
      set(v) {
        this.$emit("update:ok", v);
      },
      get() {
        return this.ok;
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>