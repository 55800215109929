<template>
  <v-col class="px-0">
    <!-- TEXT -->
    <v-text-field
      v-if="field.field_type == 'text'"
      v-model="fieldValue"
      hide-details="auto"
      :rules="notEmptyStringRule"
      :label="field.name"
      :placeholder="placeholder"
      :required="required"
      :type="field.field_type"
      outlined
      class="ml-0 mr-7"
    />
    <!-- EMAIL -->
    <v-text-field
      v-else-if="field.field_type == 'email'"
      v-model="fieldValue"
      hide-details="auto"
      append-icon="mdi-at"
      :rules="emailRule"
      :label="field.name"
      :placeholder="placeholder"
      :required="required"
      :type="field.field_type"
      outlined
      class="ml-0 mr-7"
    />
    <!-- NUMBER -->
    <v-text-field
      v-else-if="field.field_type === 'number'"
      v-model.number="fieldValue"
      hide-details="auto"
      append-icon="mdi-numeric"
      :rules="notEmptyNumberRule"
      :label="field.name"
      :placeholder="placeholder"
      :required="required"
      :type="field.field_type"
      outlined
      class="ml-0 mr-7"
    />
    <!-- TEXTAREA -->
    <v-textarea
      v-else-if="field.field_type === 'textarea'"
      v-model="fieldValue"
      hide-details="auto"
      rows="1"
      :rules="notEmptyStringRule"
      :label="field.name"
      :placeholder="placeholder"
      auto-grow
      :required="required"
      outlined
      class="ml-0 mr-8"
    />
    <!-- DATE -->
    <TimeSelector
      v-else-if="field.field_type === 'date' && (typeof fieldValue !== 'string')"
      v-model="fieldValue"
      :label="field.name"
      :required="required"
      class="ml-0 mr-8"
    />
    <!-- FILE -->
    <!-- <v-file-input
      v-else-if="field.field_type === 'file'"
      v-model="fieldValue"
      hide-details="auto"
      :accept="file_type_parser(field.metadata.accept)"
      :required="required"
    /> -->
    <!-- OTHER -->
    <template v-else>
      Champ inconnu !
      {{ field }}
    </template>
  </v-col>
</template>

<script>
import VInput from "vuetify/lib/components/VInput/VInput.js";
import TimeSelector from "@/components/Inputs/TimeSelector.vue";

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default {
  name: "FopField",
  components: {
    TimeSelector,
  },
  extends: VInput,
  props: ["field", "value"],
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    placeholder() {
      return (this.field.metadata && this.field.metadata.placeholder) || "";
    },
    required() {
      return Boolean(this.field.metadata && this.field.metadata.fieldrequired);
    },
    notEmptyNumberRule() {
      if (!this.required) return [];
      return [(v) => v === 0 || !!v || "Champ requis"];
    },
    notEmptyStringRule() {
      if (!this.required) return [];
      return [(v) => (!!v && !!v.trim()) || "Champ requis"];
    },
    emailRule() {
      if (!this.required)
        return [(v) => !v || emailRegex.test(v) || "Email invalide"];
      return [
        (v) => (!!v && !!v.trim()) || "Champ requis",
        (v) => emailRegex.test(v) || "Email invalide",
      ];
    },
  },
  methods: {
    file_type_parser(accept) {
      if (accept === "image") return "image/*";
      if (accept === "any" || accept.length === 0) return "*";
      if (accept.length !== undefined) return accept.join(",");
    },
  },
};
</script>