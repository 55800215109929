<template>
  <v-card flat>
    <v-card-title
      class="mb-2 text-h4"
      style="word-break: keep-all"
    >
      {{ (edit && "Modifier") || "Ajouter" }} un évènement
      <v-spacer />
      <v-btn
        dark
        color="blue"
        to="/myevents"
      >
        <v-icon
          left
          dark
        >
          mdi-arrow-left
        </v-icon>Retour
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      Cette page permet de {{ (edit && "modifier") || "créer" }} un évènement.
    </v-card-subtitle>

    <v-card-text v-if="event">
      <v-row>
        <v-col cols="auto">
          <v-stepper
            v-model="step"
            vertical
            class="mb-12"
          >
            <!-- Event details -->
            <v-stepper-step
              :editable="step > 0"
              :complete="step > 0"
              step="1"
            >
              Évènement
              <small>Informations générales</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card
                elevation="2"
                outlined
                color="grey lighten-5"
                class="mb-6 pr-6"
              >
                <v-card-title>Groupe</v-card-title>
                <v-card-subtitle
                  v-if="!edit"
                  class="pb-0"
                >
                  Choisissez le groupe pour lequel vous souhaitez créer cet
                  évènement.
                </v-card-subtitle>
                <v-card-actions>
                  <v-container fluid>
                    <v-select
                      v-model="event.group"
                      :disabled="edit"
                      :items="groups_available"
                      no-data-text="Aucun groupes"
                      hide-details="auto"
                    />
                  </v-container>
                </v-card-actions>
              </v-card>
              <v-card
                elevation="2"
                outlined
                color="grey lighten-5"
                class="mb-6"
                :disabled="!event.group"
              >
                <v-card-title
                  id="details"
                >
                  Détails de l'événement
                </v-card-title>
                <v-card-subtitle class="pb-0">
                  Pour les évènements publics, les éléments de cette section seront affichés sur le calendrier.
                </v-card-subtitle>
                <v-card-actions>
                  <EventDescriptionEdit
                    ref="details"
                    v-model="event"
                    :edit="edit"
                    :ok.sync="detailsOk"
                  />
                </v-card-actions>
              </v-card>
              <v-btn
                color="primary"
                :disabled="!detailsOk"
                @click="nextStep(1)"
              >
                Continuer
              </v-btn>
            </v-stepper-content>

            <!-- Fop details -->
            <v-stepper-step
              :editable="step > 1"
              :complete="step > 1"
              step="2"
            >
              Détails
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card
                elevation="1"
                outlined
                color="grey lighten-5"
                class="mb-6 pr-6"
              >
                <v-card-title
                  id="fopform"
                  class="pb-0"
                >
                  Visibilité
                </v-card-title>
                <v-card-actions>
                  <v-row>
                    <v-col cols="12">
                      <v-btn-toggle
                        v-model="event.event_details.public_type"
                        dense
                      >
                        <v-btn
                          value="private"
                          :icon="smallScreen"
                        >
                          <span class="hidden-sm-and-down mr-2">Privé</span>
                          <v-icon>
                            mdi-incognito
                          </v-icon>
                        </v-btn>
                        <v-btn
                          value="public_realm"
                          :icon="smallScreen"
                        >
                          <span class="hidden-sm-and-down mr-2">Public (realm)</span>
                          <v-icon>
                            mdi-account-school
                          </v-icon>
                        </v-btn>
                        <v-btn
                          value="public_all"
                          :icon="smallScreen"
                        >
                          <span
                            class="hidden-sm-and-down mr-2"
                          >Public (tout saturne)</span>
                          <v-icon>
                            mdi-earth
                          </v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12">
                      <p>
                        <v-icon
                          small
                          class="mr-1"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                        <span
                          v-if="event.event_details.public_type == 'private'"
                        >
                          Cet évènement est <b>privé</b>. Il n'apparaitra que
                          dans le calendrier des utilisateurs du groupe
                          créateur.
                        </span>
                        <span
                          v-if="
                            event.event_details.public_type == 'public_realm'
                          "
                        >
                          Cet évènement est <b>public (realm)</b>. Il apparaitra
                          dans le calendrier des utilisateurs de Saturne de même
                          nature que vous (élève ou administration).
                        </span>
                        <span
                          v-if="event.event_details.public_type == 'public_all'"
                        >
                          Cet évènement est <b>public (tout saturne)</b>. Il
                          apparaitra dans le calendrier de TOUS les utilisateurs
                          de saturne.
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-card
                elevation="1"
                outlined
                color="grey lighten-5"
                class="mb-6 pr-6"
              >
                <v-card-title
                  id="fopform"
                  class="pb-0"
                >
                  FOP (Fiche Ouverture de Prestation)
                </v-card-title>
                <v-card-actions>
                  <v-form
                    ref="fopform"
                    v-model="fopOk"
                    class="d-flex"
                  >
                    <v-row>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="requireFop"
                          :disabled="edit"
                          label="FOP"
                        />
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="event.fop.foptype"
                          :items="fop_types"
                          :loading="loadingFopFields"
                          :disabled="edit || loadingFopFields || !requireFop"
                          :rules="[(v) => !requireFop || !!v || 'Champ requis']"
                          label="Type de FOP"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="foptype"
                elevation="1"
                outlined
                color="grey lighten-5"
                class="mb-10"
              >
                <v-card-title
                  id="fields"
                  class="pb-0"
                >
                  Informations complémentaires
                </v-card-title>

                <v-card-actions>
                  <!-- Cancel stepper margin -->
                  <v-row style="max-width: calc(100% - 40px)">
                    <v-col
                      cols="12"
                      class="pa-0"
                    >
                      <FopFields
                        ref="fields"
                        v-model="event.fop.data"
                        :foptype="foptype"
                        :ok.sync="fieldsOk"
                      />
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="
                  foptype &&
                    event.fop.steps &&
                    permissions.modify_fop_steps &&
                    !edit
                "
                elevation="1"
                outlined
                color="grey lighten-5"
                class="mb-5"
              >
                <v-card-title class="pb-0">
                  Parcours de la FOP
                </v-card-title>
                <v-card-text>
                  <!-- Cancel the stepper margin -->
                  <EventTimeline
                    edit
                    :event.sync="event"
                    style="max-width: calc(100% - 40px)"
                  />
                </v-card-text>
              </v-card>
              <v-btn
                color="primary"
                :disabled="!fieldsOk || !fopOk || loadingFopFields"
                @click="nextStep(2)"
              >
                Continuer
              </v-btn>
              <v-btn
                text
                @click="step = 1"
              >
                Précédent
              </v-btn>
            </v-stepper-content>

            <!-- Resume -->
            <v-stepper-step
              :editable="step > 2"
              :complete="step > 2"
              step="3"
            >
              Résumé
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card
                v-if="!requireFop || foptype"
                outlined
                class="ma-3"
              >
                <v-card-title>
                  <v-chip
                    color="teal"
                    class="mr-4"
                    label
                    text-color="white"
                    small
                  >
                    {{ (requireFop && foptype.name) || "N/A" }}
                  </v-chip>
                  <span>
                    {{ event.name }}
                  </span>
                </v-card-title>
                <v-card-text
                  style="max-width: calc(100% - 50px)"
                  class="text-justify"
                >
                  <FopSummary :event="event" />
                  <p class="pt-6 mb-0">
                    <v-icon
                      small
                      class="mr-1"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                    Évènement
                    <span v-if="event.event_details.public_type == 'private'">
                      <b>privé</b>
                    </span>
                    <span
                      v-if="event.event_details.public_type == 'public_realm'"
                    >
                      <b>public (realm)</b>
                    </span>
                    <span
                      v-if="event.event_details.public_type == 'public_all'"
                    >
                      <b>public (tout saturne)</b>
                    </span>
                  </p>
                </v-card-text>
              </v-card>
              <v-btn
                color="primary"
                :disabled="loadingSubmit"
                :loading="loadingSubmit"
                @click="nextStep(3)"
              >
                Soumettre
              </v-btn>
              <v-btn
                text
                :disabled="loadingSubmit"
                @click="step = 2"
              >
                Précédent
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-text>
    <v-progress-circular
      v-else
      class="d-block mx-auto"
      indeterminate
    />
  </v-card>
</template>

<script>
import EventDescriptionEdit from "@/components/Event/EventDescriptionEdit";
import EventTimeline from "@/components/Event/EventTimeline";
import FopFields from "@/components/Event/FopFields";

import FopSummary from "@/components/Fop/Summary";

export default {
  name: "AddEditEvent",
  components: {
    EventDescriptionEdit,
    EventTimeline,
    FopFields,
    FopSummary,
  },
  props: {
    edit: Boolean,
    event_id: {
      type: String,
      default: "",
    }
  },
  data: () => {
    const n = new Date(Date.now());
    const begindate = new Date(
      n.getFullYear(),
      n.getMonth(),
      n.getDate() + 1,
      n.getHours(),
      n.getMinutes()
    );
    const enddate = new Date(begindate.getTime() + 24 * 60 * 60 * 1000);
    return {
      step: 1,
      detailsOk: true,
      fopOk: true,
      fieldsOk: true,
      event: {
        group: undefined,
        event_details: {
          begin_date: begindate,
          end_date: enddate,
          description: "",
          locations: [],
          public_type: "public_realm",
        },
        fop: {
          data: [],
        },
      },
      requireFop: true,
      loadingSubmit: false,
      loadingFopFields: false,
      groups_available: [],
      fop_types: [],
      foptype: undefined,
      permissions: {
        modify_fop_steps: false,
      },
    };
  },
  computed: {
    smallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    "event.fop.foptype": function (v) {
      if (this.requireFop) this.changeFopStructure();
    },
    requireFop(v) {
      if (!v) {
        this.foptype = undefined;
        this.fieldsOk = true;
        this.fopOk = true;
        this.event.fop = {};
      }
    },
  },
  created() {
    if (this.edit) {
      if (!this.event_id) {
        return this.$router.push("/404");
      }
      this.event = undefined;
      this.axiosPost(
        process.env.VUE_APP_API_URL + "/events/getEventEditDetails",
        {
          id: this.event_id,
        }
      )
        .then((res) => {
          let event = res.data;
          event.event_details.begin_date = new Date(
            event.event_details.begin_date
          );
          event.event_details.end_date = new Date(event.event_details.end_date);
          event.event_details.public_type = "public_realm",

          this.event = event;
          this.updateGroups();
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    } else {
      this.updateGroups();
    }
  },
  methods: {
    async nextStep(step) {
      switch (step) {
        case 1:
          if (!this.$refs.details.validate()) {
            await this.$vuetify.goTo("#details", { container: "#main" });
            return;
          }
          this.updateFoptypes().then(() => {
            if (this.edit && this.event.fop){
              const date_fields = this.event.fop.fopfields.filter(x => x.field_type === "date").map(x => x._id);
              this.event.fop.data.filter(x => date_fields.includes(x.field_id)).forEach(x => x.value = new Date(x.value));
            }
            this.step = 2;
          });
          break;
        case 2:
          if (!this.$refs.fopform.validate()) {
            await this.$vuetify.goTo("#fopform", { container: "#main" });
            return;
          }
          if (this.foptype && !this.$refs.fields.validate()) {
            await this.$vuetify.goTo("#fields", { container: "#main" });
            return;
          }
          if (this.loadingFopFields) return;
          this.step = 3;
          break;
        case 3:
          // send form
          this.loadingSubmit = true;
          if (this.edit) {
            return this.axiosPost(
              process.env.VUE_APP_API_URL + "/events/editEvent",
              { event: this.event }
            )
              .then(() => {
                this.$alert.$emit("snackbar", {
                  message: "Evenement modifié !",
                  status: "success",
                });
                this.$router.push("/myevents?id=" + this.event_id);
              })
              .catch((err) => {
                this.loadingSubmit = false;
                this.$alert.$emit("snackbar", {
                  message: err.response.data,
                  status: "error",
                });
              });
          } else {
            return this.axiosPost(
              process.env.VUE_APP_API_URL + "/events/createEvent",
              { event: this.event }
            )
              .then(() => {
                this.$alert.$emit("snackbar", {
                  message: "Evenement créé !",
                  status: "success",
                });
                this.$router.push("/myevents");
              })
              .catch((err) => {
                console.log(err);
                this.loadingSubmit = false;
                this.$alert.$emit("snackbar", {
                  message: err.response.data,
                  status: "error",
                });
              });
          }
      }
    },
    updateFoptypes() {
      return this.axiosPost(
        process.env.VUE_APP_API_URL +
          "/groups/" +
          this.event.group +
          "/getCreatableFoptypes"
      )
        .then((res) => {
          this.fop_types = res.data.map((x) => ({ text: x.name, value: x.id }));
          if (this.requireFop && !this.event.fop.foptype && this.fop_types.length) {
            this.$set(this.event.fop, "foptype", this.fop_types[0].value);
            this.changeFopStructure();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    updateGroups() {
      this.axiosPost(
        process.env.VUE_APP_API_URL + "/groups/getUserGroupsWithLevel"
      )
        .then((res) => {
          this.groups_available = res.data
            .filter(
              (x) =>
                ["admin", "member"].includes(x.level) &&
                x.group.fop_types.some((y) => y.permissions.create)
            )
            .map((x) => ({ text: x.group.name, value: x.group._id }));
          if (!this.event.group && this.groups_available.length)
            this.$set(this.event, "group", this.groups_available[0].value);
        })
        .catch((err) => {
          console.log(err);
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    changeFopStructure() {
      this.loadingFopFields = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/fops/getFOPType", {
        id: this.event.fop.foptype,
      })
        .then((res) => {
          this.loadingFopFields = false;
          this.permissions = res.data.permissions;
          let foptype = res.data.foptype;
          this.event.fop.steps = foptype.steps_template.steps;
          this.event.fop.fopfields = foptype.fields;
          if (!this.event.fop.data) this.event.fop.data = [];
          this.foptype = foptype;
        })
        .catch((err) => {
          console.log(err);
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
      return;
    },
  }
};
</script>